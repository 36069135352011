.cookie-bar {
  background: #E93247;
  display: flex;
  align-items: center;
  padding: 27px 0;
  position: fixed;
  z-index: 300;
  bottom: 0;
  left: 0;
  right: 0;
  transition: bottom 0.35s ease;

  @include respond-down(L) {
    padding: 20px 0;
  }

  .mini-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-down(XS) {
      flex-direction: column;
    }
  }

  .info {
    @include respond-property('font-size', 16px, 16px, 14px, 16px, 14px, 13px);
    color: #FFF;
    margin-right: 30%;
    line-height: 1.45em;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    @include respond-down(L) {
      margin-right: 15%;
    }
    @include respond-down(M) {
      margin-right: 30px;
    }
    @include respond-down(XS) {
      margin-right: 0;
      margin-bottom: 16px;
      text-align: center;
    }
  }

  .big-button {
    flex: 0 0 auto;
  }
}

body {
  transition: margin-bottom 0.35s ease;
  margin-bottom: 110px;

  @include respond-down(S) {
    margin-bottom: 160px;
  }

  &.cookie-bar-invisible {
    margin-bottom: 0;

    .cookie-bar {
      bottom: -120px;

      @include respond-down(S) {
        bottom: -160px;
      }
    }
  }
}

