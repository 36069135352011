.big-button {
  margin: auto;
  user-select: none;
  display: flex;
  position: relative;
  z-index: 90;
  color: #FFF;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 0.01em;
  cursor: pointer;
  width: 248px;
  height: 56px;

  @include respond-down(L){
    font-size: 14px;
    width: 192px;
    height: 42px;
  }

  .text {
    border-radius: 100px;
    background: #E93247;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: inset 0 0 0 2px transparent;
    padding-top: 0.15em;
    transition: box-shadow 0.25s ease, background 0.15s ease;
  }
  
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: #F5A9B1;
    border-radius: 100px;
    transform: translate(5px, 7px);
    transition: transform 0.25s ease;

    @include respond-down(L){
      transform: translate(4px, 5px);
    }
  }

  &.disabled {
    cursor: default;

    .text {
      background: #F5A9B1;
    }
  }

  &.invert {
    .text {
      background: #FFF;
      color: #E93247;
    }
  }

  &.bordered {
    .text {
      background: transparent;
      box-shadow: inset 0 0 0 2px #b42838;
    }
  }

  &:focus:not(.disabled) {
    .text {
      box-shadow: inset 0 0 0 2px #b42838;
    }
  }

  @include respond-up(L){
    &:hover:not(.disabled),
    &.disabled {
      .text {
        box-shadow: inset 0 0 0 2px #F5A9B1;
      }

      &:before {
        transform: translate(0, 0);
      }
    }
  }

  &:active:not(.disabled) {
    .text {
      background: #b42838;
    }
  }
}
