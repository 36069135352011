.quick-note {
  position: fixed;
  width: 140px;
  background: #E93247;
  color: #FFF;
  height: 46px;
  border-radius: 50px;
  bottom: 26px;
  left: 50%;
  margin-left: -70px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 600;
  transform: translateY(100px);
  transition: transform 0.35s ease, opacity 0.30s linear;

  &.show {
    transform: translateY(0);
  }
  &.hide {
    opacity: 0;
  }
}

.find-filters {
  position: fixed;
  left: -100vw;
  right: 100vw;
  opacity: 0;
  transform: scale(0.7);
  transition: opacity 0.35s ease, transform 0.35s ease;
  top: 0;
  bottom: 0;
  overflow: auto;
  background: #E9F8F9;
  z-index: 500;

  .top-filters {
    @include respond-property('padding-top', 120px, 75px, 40px, 50px, 36px, 36px);
    @include respond-property('padding-bottom', 30px, 20px, 20px, 1px, 1px, 1px);
    background: #FCE8EB;
    position: relative;
    text-align: center;

    .close {
      position: absolute;
      right: 10%;
      top: 20px;
      padding: 10px;
      cursor: pointer;

      @include respond-down(L){
        right: 6%;
      }

      svg {
        @include respond-down(L){
          width: 16px;
        }
      }

      path {
        fill: #E93247;
      }
    }

    .decorator {
      &.butterfly {
        position: absolute;
        left: 14%;
        top: 90px;

        @include respond-down(L){
          top: 50px;
          width: 40px;
        }
        @include respond-down(M){
          display: none;
        }
      }

      &.cloud {
        position: absolute;
        top: 100px;
        right: 30%;

        @include respond-down(L){
          top: 50px;
          right: 20%;
          width: 94px;
        }
        @include respond-down(M){
          right: 69%;
        }
      }
    }

    .filters-header {
      @include respond-property('font-size', 45px, 45px, 31px, 35px, 25px, 25px);
      @include respond-property('margin-bottom', 76px, 58px, 30px, 60px, 60px, 60px);
      line-height: 1.0em;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
    }

    .moods-list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      height: 4 * 64px;
      margin-bottom: 40px;

      @include respond-down(L){
        height: 4 * 50px;
        margin-bottom: 20px;
      }
      @include respond-down(M){
        margin-bottom: 0;
        width: 100%;
        padding: 0 15px;
        flex-direction: column;
        height: 8 * 50px;
        color: #232323;
        display: none;
        opacity: 0;
        transition: opacity 0.35s ease;
        transition-delay: 0.25s;
      }
      @include respond-down(S){
        padding: 0 10px;
      }
      @include respond-down(XS){
        flex-direction: initial;
        height: 16 * 50px;
      }

      .mood-item {
        display: flex;
        align-items: center;
        height: 64px;
        width: 24%;
        margin-right: 1%;
        padding-left: 0.2em;
        position: relative;
        cursor: pointer;
        user-select: none;
        max-width: 310px;

        @include respond-down(L){
          height: 50px;
          width: 24.5%;
          margin-right: 0.5%;
        }
        @include respond-down(M){
          width: 48%;
          margin-right: 1%;
          margin-left: 1%;
        }
        @include respond-down(XS){
          width: 100%;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 7px;
          bottom: 7px;
          right: 0;
          border-radius: 64px;
          background: #FFF;
          transition: opacity 0.25s ease, transform 0.25s ease;
          opacity: 0;
          transform: scale(0.8);

          @include respond-down(L){
            top: 5px;
            bottom: 5px;
          }
          @include respond-down(M){
            background: #FCE8EB;
          }
        }

        .name {
          @include respond-property('font-size', 20px, 18px, 16px, 16px, 14px, 14px);
          position: relative;
          z-index: 10;
          padding: 0 0.5em;
          transition: color 0.25s ease;

          @include respond-down(L){
            padding: 0.1em 0 0 0;
            margin-left: -7px;
          }
        }

        @include respond-up(L){
          &:hover {
            &:before {
              opacity: 0.6;
              transform: scale(1);
            }
          }
        }

        &.active {
          &:before {
            opacity: 1;
            transform: scale(1);
          }

          .mood-icon-wrapper {
            .back {
              fill: #ED5C6D;
            }
          }
          
          .name {
            color: #ED5C6D;
          }
        }
      }
    }

    .moods-list-button {
      transition: all 0.35s ease;
      margin-bottom: 30px;
      border-radius: 25px;

      .text {
        border-radius: 25px;
        transition: all 0.35s ease;
      }

      @include respond-up(L){
        display: none;
      }
    }
  }

  .bottom-filters {
    @include respond-property('padding-top', 38px, 25px, 25px, 28px, 30px, 30px);
    @include respond-property('padding-bottom', 100px, 70px, 36px, 40px, 30px, 235px);
    position: relative;

    .decorator {
      position: absolute;

      &.da {
        right: 4%;
        bottom: 100px;

        @include respond-down(L){
          width: 180px;
          bottom: 0;
        }
        @include respond-down(M){
          display: none;
        }
      }

      &.db {
        left: 4%;
        bottom: 0px;

        @include respond-down(L){
          width: 238px;
        }
        @include respond-down(M){
          width: 325px;
          transform: scaleX(-1);
          left: auto;
          right: 10%;
          bottom: 130px;
        }
        @include respond-down(S){
          width: 248px;
          bottom: 120px;
          right: 8%;
        }
        @include respond-down(XS){
          width: 248px;
          bottom: 20px;
          right: 50%;
          margin-right: -124px;
        }
      }

      &.cloud {
        left: 14%;
        top: 40px;

        @include respond-down(L){
          width: 68px;
          left: 8%;
        }

        @include respond-down(M){
          top: auto;
          left: 58%;
          bottom: 390px;
        }
        @include respond-down(S){
          left: 51%;
          bottom: 370px;
          transform: scale(-1,1);
        }
        @include respond-down(XS){
          //left: 68%;
          //bottom: 390px;
        }
      }

      &.butterfly {
        display: none;

        @include respond-down(S){
          display: block;
          right: 20%;
          width: 30px;
          top: 320px;
        }
      }

      &.cloud-2 {
        display: none;

        @include respond-down(S){
          display: block;
          right: 3%;
          top: 270px;
          width: 27%;
        }
      }
    }

    .types-list {
      display: flex;
      justify-content: center;
      margin-bottom: 70px;
      padding-left: 5%;
      position: relative;
      z-index: 10;

      @include respond-down(L){
        margin-bottom: 50px;
      }
      @include respond-down(M){
        flex-wrap: wrap;
        margin-bottom: 0;
      }
      @include respond-down(XS){
        padding-left: 0;
      }

      .type-item {
        flex: 0 0 20%;

        @include respond-down(M){
          flex: 1 1 50%;
          margin-bottom: 50px;
        }
        @include respond-down(XS){
          margin-bottom: 30px;
        }

        .type-header {
          @include respond-property('font-size', 23px, 23px, 18px, 28px, 20px, 20px);
          @include respond-property('margin-bottom', 26px, 26px, 18px, 30px, 22px, 22px);
          text-transform: uppercase;
          color: #9DCDD1;

          @include respond-down(M){
            font-weight: 400;
          }
        }
      }
    }
    .results-finded-wrapper {
      text-align: center;

      .results-finded {
        margin: 0 auto 30px auto;
        min-height: 36px;
        background: #FCE8EB;
        border-radius: 40px;
        align-items: center;
        padding: 12px 20px;
        font-size: 16px;
        color: #E93247;
        display: inline-flex;
        justify-content: center;
        position: relative;
        z-index: 20;

        @include respond-down(S){
          margin-bottom: 20px;
        }
      }
    }

    .button {
      svg {
        position: absolute;
        right: 55px;
        top:10px;
        transform: rotate(20deg);
        transition: transform 0.25s ease;
        z-index: 30;

        @include respond-down(L){
          width: 15px;
          right: 45px;
          top: 4px;
        }
      }

      &:hover {
        svg {
          transform: rotate(340deg);
        }
      }
    }
  }
}

body.filters-animated {
  .find-filters {
    opacity: 1;
    transform: scale(1);
  }
}

body.filters-opened {
  overflow: hidden;

  .find-filters {
    left: 0;
    right: 0;
  }
}

body.moods-opened {
  .moods-list-button {
    .text {
      .moods-list {
        display: flex;
      }
    }
  }
}

body.moods-animated {
  .moods-list-button {
    width: 100%;
    border-radius: 25px;
    height: 8 * 50px + 30px;

    @include respond-down(S){
      height: 8 * 50px + 20px;
    }
    @include respond-down(XS){
      height: 16 * 50px + 20px;
    }

    &:before {
      background: transparent;
    }

    .text {
      //box-shadow: none;
      height: 8 * 50px + 30px;
      background: #FFF !important;
      border-radius: 25px;
      box-shadow: inset 0 0 0 2px #e932474f;

      @include respond-down(S){
        height: 8 * 50px + 20px;
      }
      @include respond-down(XS){
        height: 16 * 50px + 20px;
      }

      .moods-list {
        display: flex;
        opacity: 1;
      }
    }
  }}



.mood-icon-wrapper {
  position: relative;
  z-index: 10;
  width: 50px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  svg {
    margin-top: 3px;

    .back {
      transition: fill 0.25s ease, transform 0.25s ease;
    }

    @include respond-down(L){
      transform: scale(0.75) translateX(-4px);
    }
  }

  &.svg-mood-action svg {
    margin-left: 4px;
  }

  &.svg-mood-gift svg {
    margin-bottom: 4px;
    margin-left: -2px;
  }

  &.svg-mood-saint svg {
    margin-bottom: 9px;
  }

  &.svg-mood-all svg {
    margin-bottom: 4px;
  }

  &.svg-mood-romantique svg {
    width: 36.3px;
  }

  &.svg-mood-ziza svg {
    width: 36.3px;
    margin-bottom: 6px;
    @include respond-down(L){
      margin-bottom: 3px;
    }
  }

  &.svg-mood-talk svg {
    width: 44px;
    margin-bottom: 10px;
    margin-left: -5px;
    margin-right: -10px;

    @include respond-down(L){
      margin-bottom: 8px;
    }
  }
}

@include respond-up(L){
  .bottle-empty {
    .bg {
      transition: transform 0.35s ease;
    }

    &:hover {
      .back {
        transform: translate(-3px, -3px);
      }
      .bg {
        transform: translate(1476px, 2682px);
      }
    }
  }

  .bottle-cart:hover .mood-icon-wrapper,
  .emotion:hover {
    .back {
      transform: translate(-4px, -4px);
    }

    &.svg-mood-all svg .back {
      transform: translate(-2px, -2px);
    }
    &.svg-mood-action svg .back {
      transform: translate(-1px, -2px);
    }
    &.svg-mood-sleep svg .back {
      transform: translate(2px, 2px);
    }
    &.svg-mood-romantique svg .back {
      transform: translate(2px, 2px);
    }
    &.svg-mood-hi-hi svg .back {
      transform: translate(2px, 2px);
    }
    &.svg-mood-plak-plak svg .back {
      transform: translate(2px, 2px);
    }
    &.svg-mood-simple svg .back {
      transform: translate(2px, 2px);
    }
    &.svg-mood-alone svg .back {
      transform: translate(2px, 2px);
    }
    &.svg-mood-palma svg .back {
      transform: translate(-4px, -2px);
    }
    &.svg-mood-ziza svg .back {
      transform: translate(1px, 6px)
    }
    &.svg-mood-camping svg .back {
      transform: translate(-3px, -2px);
    }

    &.svg-special-1 svg .back,
    &.svg-special-2 svg .back,
    &.svg-special-3 svg .back {
      transform: translate(-3px, -3px);
    }

  }
}

