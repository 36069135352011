* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  overflow-x: hidden;
  position: relative;
  font-family: 'Mitias', sans-serif;
  color: #232323;
  &::selection {}
}

a {
  text-decoration: none;
  transition: color .25s ease;
  color: #232323;
}

.link {
  @include respond-up(L){
    &:hover {
      color: #E81E35;
    }
  }
}

[data-link] {
  cursor: pointer;
}

.body {
  position: relative;
}

.container {
  width: 1580px;
  margin: auto;

  @include respond-down(XL){
    width: auto;
    margin: 0 7%;
  }
  @include respond-down(L){
    margin: 0 6%;
  }
  @include respond-down(M){
    margin: 0 4%;
  }
}

.mini-container {
  max-width: 1200px;
  margin: auto;
  position: relative;

  @include respond-down(XL){
    margin: 0 17%;
  }
  @include respond-down(L){
    margin: 0 8%;
  }
  @include respond-down(M){
    margin: 0 4%;
  }
}

[data-preload] {
  display: none;
}


@keyframes scale {
  0% { transform: scale(0.8); }
  50% { transform: scale(1); }
  100% { transform: scale(0.8); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes updown {
  0% { transform: translateY(5px) rotate(-12deg); }
  50% { transform: translateY(-10px) rotate(12deg); }
  100% { transform: translateY(5px) rotate(-12deg); }
}
