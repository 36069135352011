
.bottle-items-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 40px;

  @include respond-down(S){
    padding-bottom: 10px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  //align-items: center;
}

.bottle-cart-wrapper {
  flex: 0 0 25%;
  display: flex;
  justify-content: space-around;

  @include respond-down(M){
    flex: 0 0 33.333333333%;
  }
  @include respond-down(S){
    flex: 0 0 50%;
  }

  .bottle-cart {
    @include respond-property('margin-bottom', 100px, 78px, 60px, 45px, 0, 0);
    text-align: center;
    text-transform: uppercase;
    margin-top: -30px;
    padding: 30px 30px 0 30px;
    display: block;
    width: auto;
    margin-left: 14px;
    margin-right: 14px;
    flex: 1 1 100%;

    @include respond-down(L){
      margin-left: 8px;
      margin-right: 8px;
    }
    @include respond-down(S){
      padding: 20px 0 0 0;
      margin-right: 0;
      margin-left: 0;
      margin-top: -10px;
    }

    .media {
      position: relative;
      width: 200px;
      margin: 0 auto 14px auto;

      @include respond-down(XL){
        margin-bottom: 10px;
      }
      @include respond-down(L){
        width: 146px;
      }
      @include respond-down(S){
        margin-bottom: 8px;
      }

      .bottle-picture {
        width: 140px;
        height: 260px;
        margin: auto;
        background: no-repeat center center;
        background-size: contain;
        position: relative;
        z-index: 50;
        opacity: 0;
        transition: opacity 0.6s ease;

        &.bg-loaded {
          opacity: 1;
        }

        @include respond-down(L){
          height: 180px;
        }
        @include respond-down(S){
          height: 160px;
        }
      }

      &.empty {
        margin-bottom: 36px;

        @include respond-down(L){
          margin-bottom: 20px;
        }

        img, svg.img {
          height: 250px;
          margin-top: 10px;

          @include respond-down(L){
            margin-top: 0px;
            height: 180px;
          }
          @include respond-down(S){
            height: 160px;
          }
        }
      }

      .color-line {
        opacity: 0.75;
        position: absolute;
        z-index: 40;
        left: 50%;
        bottom: 20%;
        width: 200px;
        margin-left: -100px;
        transition: transform 0.95s ease;

        @include respond-down(L){
          width: 150px;
          margin-left: -75px;
        }
      }

      .emotion {
        z-index: 60;
        position: absolute;
        left: 16px;
        top: 40px;

        &.special {
          top: 96px;

          @include respond-down(L){
            top: 68px;
          }
        }

        @include respond-down(L){
          left: 0px;
          top: 30px;
        }
      }

      .back-image {
        width: 100%;
        height: 200px;
        background: #FFF no-repeat center center;
        background-size: contain;
        border: 10px solid #FFF;
        position: absolute;
        z-index: 30;
        bottom: 24px;
        top: 30px;
        opacity: 0;
        transition: transform 0.25s ease-in-out, opacity 0.45s ease;
        transform: scale(1.15);

        @include respond-down(L){
          height: 146px;
        }
      }
    }

    .meta {
      font-weight: bold;
      font-family: 'Lora', serif;
      letter-spacing: 0.06em;

      .year {
        font-size: 16px;
        color: #4E4E4E;
        margin-bottom: 18px;

        @include respond-down(L){
          font-size: 14px;
          margin-bottom: 12px;
        }
        @include respond-down(S){
          font-size: 12px;
          margin-bottom: 7px;
        }
      }

      .brand {
        font-size: 14px;
        margin-bottom: 18px;
        color: #8D8888;

        @include respond-down(L){
          font-size: 12px;
          margin-bottom: 7px;
        }
        @include respond-down(S){
          font-size: 10px;
          margin-bottom: 5px;
        }
      }
    }

    .name {
      font-size: 23px;
      color: #232323;

      &.empty {
        color: #E93247;
        text-decoration: underline;
        font-weight: 400;
        line-height: 1.45em;
      }

      @include respond-down(L){
        font-size: 18px;
      }
      @include respond-down(S){
        font-size: 16px;
      }
    }

    @include respond-up(L){
      &.hover, &:hover {
        .media {
          & > img {
            //transform: scale(1.06);
          }
          .color-line {
            transform: translateX(-8px) scale(1);
          }
          .back-image {
            opacity: 1;
            transform: translateY(0);
          }
          .emotion .circle {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
  }
}



