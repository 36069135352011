.age-confirm {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 400;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(252, 232, 235, 0.87);
  transition: opacity 0.35s ease;

  .info-block {
    @include respond-property('width', 560px, 560px, 390px, 460px, 460px, 90%);
    background: #FFF;
    border-radius: 30px;
    border: 7px solid #FCE8EB;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 56px 74px 0 74px;
    position: relative;
    transition: transform 0.35s ease;

    @include respond-down(L){
      padding: 34px 28px 0 28px;
    }

    .closer {
      padding: 10px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;

      svg {
        @include respond-down(L){
          width: 18px;
        }
        path {
          fill: #F5A9B1;
          transition: fill 0.25s ease;
        }
      }

      &:hover {
        svg path {
          fill: #E93247;
        }
      }
    }

    .decorator {
      position: absolute;

      &.butterfly {
        top: 89px;
        left: 78px;
        width: 45px;
        transform: rotate(120deg);

        @include respond-down(L){
          top: 54px;
          left: 48px;
          width: 30px;
        }
      }

      &.cloud-1 {
        top: 140px;
        right: 67px;
        width: 100px;

        @include respond-down(L){
          top: 100px;
          right: 30px;
          width: 70px;
        }
      }

      &.cloud-2 {
        left: 106px;
        bottom: 150px;
        width: 60px;
        transform: scaleX(-1);

        @include respond-down(L){
          bottom: 140px;
          width: 50px;
          left: 40px;
        }
        @include respond-down(XS){
          display: none;
        }
      }

    }

    .circle {
      flex: 0 0 auto;
      width: 116px;
      height: 116px;
      border-radius: 116px;
      border: 4px solid #E93247;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 50px;
      color: #E93247;
      margin-bottom: 40px;

      @include respond-down(L){
        width: 84px;
        height: 84px;
        font-size: 40px;
        margin-bottom: 20px;
        border: 3px solid #E93247;
      }
    }

    .important {
      @include respond-property('font-size', 23px, 23px, 18px, 20px, 16px, 16px);
      line-height: 1.55em;
      color: #E93247;
      margin-bottom: 40px;
      text-transform: uppercase;

      @include respond-down(L){
        margin-bottom: 30px;
      }
    }

    .help {
      @include respond-property('font-size', 16px, 16px, 14px, 16px, 14px, 14px);
      line-height: 1.45em;
      color: #232323;
      padding: 0 30px;
      margin-bottom: 40px;

      @include respond-down(L){
        margin-bottom: 30px;
      }
    }

    .button {
      margin-bottom: -30px;

      @include respond-down(L){
        margin-bottom: -22px;
      }
    }
  }

  &.hide {
    opacity: 0;

    .info-block {
      transform: scale(1.3);
    }
  }
}
