.mobile-menu {
  background: #E93247;
  overflow: hidden;
  transition: max-height 0.25s ease;
  max-height: 0px;

  .menu-content {
    padding: 32px 0;
    transition: opacity 0.35s ease;
    opacity: 0;

    .mini-container {
      .close {
        position: absolute;
        right: -10px;
        top: -20px;
        padding: 20px;

        svg {
          @include respond-down(S){
            width: 18px;
          }
        }
      }
    }

    .title {
      img {
        width: 197px;
        display: block;
        margin-bottom: 74px;

        @include respond-down(XS){
          width: 172px;
        }
      }
    }

    .menu-links {
      display: flex;
      flex-direction: column;
      margin: 0 auto 30px auto;

      @include respond-down(S){
        margin-left: 0;
      }

      .item {
        display: inline-flex;
        align-items: center;
        font-size: 25px;
        color: #FFF;
        text-transform: uppercase;
        margin-bottom: 44px;

        svg {
          width: 30px;
          margin-right: 20px;

          &.dice {
            path {
              stroke: #FFF;
            }
          }

          path {
            fill: #FFF;
            //stroke: #FFF;
          }
        }
      }
    }

    .social-links {
      width: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .link {
        margin-right: 25px;
      }
    }
  }

  &.opened {
    max-height: 600px;

    .menu-content {
      opacity: 1;
    }
  }
}
