@font-face {
	font-family: "Tinos";
	src: url("Tinos-Regular.ttf");
	font-style: normal;
	font-weight: 400;
    font-display: swap;
}
@font-face {
	font-family: "Tinos";
	src: url("Tinos-Italic.ttf");
	font-style: italic;
	font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: "Tinos";
    src: url("Tinos-Bold.ttf");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}
@font-face {
    font-family: "Tinos";
    src: url("Tinos-BoldItalic.ttf");
    font-style: italic;
    font-weight: bold;
    font-display: swap;
}
