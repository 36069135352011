@import "settings";
@import "_common/grid";
@import "_common/reset";

@import "_common/base";
@import "_common/typo";
@import "_common/header-box";
@import "_common/footer-box";
@import "_common/cookie-bar";
@import "_common/email-input-wrapper";
@import "_common/buttons";
@import "_common/mobile-menu";
@import "_common/age-configm";
@import "_common/bottle-cart";

@import "_box/find-filters";

[data-debug="debug"]:before {
  position: fixed;
  content: '';
  background: rgba(255, 0, 0, 0.31);
  top: 0;
  bottom: 0;
  left: 50%;
  width: 2px;
  z-index: 99999999;
}

[data-debug="debug"]:after {
  position: fixed;
  padding: 5px;
  opacity: 0.5;
  background: #f00;
  color: #fff;
  border-radius: 5px;
  left: 10px;
  bottom: 10px;
  z-index: 99999;
  width: 24px;
  height: 24px;
  content: 'XXL';
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: sans-serif;
  font-weight: bold;
  line-height: 1em;
  font-size: 14px;
  @include respond-down(XL) { content: 'XL'; }
  @include respond-down(L)  { content: 'L';  }
  @include respond-down(M)  { content: 'M';  }
  @include respond-down(S)  { content: 'S';  }
  @include respond-down(XS) { content: 'XS'; }
}
