.email-input-wrapper {
  display: flex;
  align-items: center;

  .at {
    width: 28px;
    flex: 0 0 auto;

    @include respond(L){
      width: 22px;
    }
  }

  .input {
    flex: 1 1 100%;
    font-size: 16px;
    line-height: 22px;
    padding: 0 4px;
    border: none;
    background: transparent;
    margin: 0 8px;
    border-bottom: 2px dashed #8D8888;

    @include respond-down(M){
      font-size: 18px;
      line-height: 24px;
    }
    @include respond-down(XS){
      text-align: center;
    }
  }

  .arrow {
    width: 20px;
    flex: 0 0 auto;
  }
}
