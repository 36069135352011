
.h1 {
  @include respond-property('font-size', 42px, 42px, 31px, 28px, 25px, 25px);
  @include respond-property('margin-bottom', 24px, 24px, 17px, 14px, 13px, 10px);
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.2em;
  color: #000000;
}

.lead {
  @include respond-property('font-size', 18px, 18px, 14px, 12px, 12px, 12px);
  font-weight: bold;
  letter-spacing: 0.04em;
  color: rgba(78, 78, 78, 0.56);
  text-transform: uppercase;
  font-family: 'Tinos', serif;
}

.emotion {
  z-index: 60;

  .circle {
    display: none;
    width: 34px;
    height: 30px;
    background: #FFF;
    position: absolute;
    border-radius: 30px;
  }

  .circle {
    width: 48px;
    height: 48px;
    background: #FFF;
    border-radius: 80px;
    z-index: -1;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -24px 0 0 -24px;
    transition: transform 0.45s ease, opacity 0.25s ease;
    transform: scale(0.0);
    opacity: 0;
  }

  svg {
    z-index: 10;
    // height: 38px;
    //transform: translate(-2px, -5px);

    .back {
      transition: transform 0.25s ease;
    }

    @include respond-down(L){
      transform: scale(0.8);
      // height: 30px;
    }
  }
}

.title-meta {
  @include respond-property('font-size', 18px, 18px, 14px, 14px, 12px, 14px);
  @include respond-property('margin-bottom', 20px, 20px, 12px, 12px, 8px, 8px);
  color: #8D8888;
  text-transform: uppercase;
  font-family: 'Mitias', sans-serif;
  letter-spacing: 0.04em;
}

.brand-text {
  margin-bottom: 0.5em;
  line-height: 1.35em;
  font-family: 'Tinos', serif;
  font-weight: bold;
  font-style: italic;
  color: #8D8888;
  font-size: 22px;
  text-transform: uppercase;

  @include respond-down(L){
    font-size: 16px;
  }

  @include respond-down(XS){
    font-size: 14px;
    text-align: center;
  }
}

.rate-line {
  display: flex;

  .rate-item {
    width: 20px;
    height: 29px;
    margin-right: 8px;
    position: relative;
    overflow: hidden;

    img.glass {
      width: 100%;
      position: relative;
      z-index: 30;
    }

    img.wave {
      position: absolute;
      z-index: 10;
      width: 100px;
      max-width: none;
      top: 18px;
      left: 0;
      animation: wave_shift 2s infinite linear;
    }

    @keyframes wave_shift {
      0% { transform: translateX(0px); }
      100% { transform: translateX(-80px); }
    }

    &[data-i="0"] img.wave { transition-delay: 0.30s; transition-duration: 0.85s; }
    &[data-i="1"] img.wave { transition-delay: 0.50s; transition-duration: 0.90s; }
    &[data-i="2"] img.wave { transition-delay: 0.80s; transition-duration: 0.95s; }
    &[data-i="3"] img.wave { transition-delay: 1.20s; transition-duration: 1.00s; }
    &[data-i="4"] img.wave { transition-delay: 1.75s; transition-duration: 1.05s; }
  }
}

.price-range {
  font-size: 27px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: 'Mitias', sans-serif;

  @include respond-down(L){
    font-size: 25px;
  }

  @include respond-down(XS){
    font-size: 23px;
    text-align: center;
  }
}


.text-typo {
  @include respond-property('font-size', 22px, 20px, 19px, 19px, 17px, 17px);
  font-family: Tinos, serif;
  color: #2D2D2D;
  line-height: 1.7em;

  @include respond-down(S){
    line-height: 1.6em;
  }

  p {
    margin-bottom: 1.7em;

    @include respond-down(S){
      margin-bottom: 1.4em;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    @include respond-property('margin-bottom', 55px, 42px, 42px, 36px, 30px, 28px);

    &:last-child {
      margin-bottom: 0;
    }

    li {
      position: relative;
      margin-bottom: 1.6em;
      padding-left: 16+22px;

      @include respond-down(L){
        padding-left: 10+22px;
      }
      @include respond-down(S){
        margin-bottom: 1.4em;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 16px;
        background: #FBD7DC;
        position: absolute;
        left: 0;
        top: 0.45em;

        @include respond-down(L){
          width: 10px;
          height: 10px;
          top: 0.55em;
        }
      }
    }

    &.yellow {
      li:before {
        background: #EEE59A;
      }
    }

    &.lines {
      li:before {
        width: 20px;
        height: 4px;
        top: 0.8em;
        background: #9DCDD1;
      }
    }

    &.attention {
      li:before {
        background: #E44554;
      }
    }
  }

  .h1 {
    @include respond-property('font-size', 40px, 35px, 30px, 25px, 25px, 23px);
    font-family: 'Mitias', sans-serif;
  }

  .h2 {
    font-size: 33px;
    margin-bottom: 1.6em;
    font-family: Tinos, serif;
    font-weight: bold;
    font-style: italic;

    @include respond-down(L) {
      font-size: 25px;
    }

    br {
      @include respond-down(M) {
        display: none;
      }
    }
  }

  .quoted {
    border-left: 4px solid #FCE8EB;
    padding-left: 37px;

    @include respond-down(L) {
      border-left: 3px solid #FCE8EB;
      padding-left: 32px;
    }

    @include respond-down(S) {
      margin-left: -2px;
      border-left: 4px solid #FCE8EB;
      padding-left: 20px;
    }

    @include respond-down(XS) {
      padding-left: 16px;
    }
  }
}

.type-selectors {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;

  @include respond-down(L){
    font-size: 16px;
  }

  .selector {
    @include respond-property('margin-bottom', 16px, 16px, 7px, 16px, 7px, 7px);
    display: flex;
    align-items: center;
    position: relative;
    margin-left: -40px;
    padding: 4px;
    cursor: pointer;
    transition: color 0.25s ease;

    @include respond-down(M){
      margin-left: 0;
    }

    .bullet {
      background: #FFF;
      width: 16px;
      height: 16px;
      border-radius: 30px;
      margin-right: 20px;
      position: relative;
      flex: 0 0 auto;

      @include respond-down(XS){
        margin-right: 10px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        margin: -5px 0 0 -5px;
        background: #E93247;
        border-radius: 20px;
        transition: opacity 0.25s ease, transform 0.25s ease;
        opacity: 0;
        transform: scale(0.2);
      }

      @include respond-up(XL){
        width:  18px;
        height: 18px;
      }

      @include respond(M){
        width:  30px;
        height: 30px;
      }
    }

    @include respond-up(L){
      &:hover {
        color: #E93247;

        .bullet:after {
          opacity: 1;
          transform: scale(0.55);
        }
      }
    }

    &.selected {
      color: #E93247;

      .bullet:after {
        opacity: 1;
        transform: scale(1);
      }
    }

    &.disabled, &.disabled:hover {
      color: #aaa;
      cursor: default;

      .bullet:after {
        opacity: 0;
      }
    }

  }
}


