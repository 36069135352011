.footer-box {
  background: #FFF;
  position: relative;
  z-index: 70;

  .mini-container {
    max-width: 1450px;

    @include respond-down(XL){
      margin: 0 12%;
    }
    @include respond-down(M){
      margin: 0 4%;
    }
  }

  .social-line {
    z-index: 30;
    display: flex;
    align-items: center;
    padding: 60px 0 30px 0;
    justify-content: space-between;
    position: relative;

    @include respond-down(L){
      padding: 30px 0;
    }
    @include respond-down(M){
      align-items: flex-start;
    }
    @include respond-down(S){
      padding: 50px 0 30px 0;
    }
    @include respond-down(XS){
      flex-direction: column;
      align-items: center;
    }

    .decorator {
      position: absolute;
      bottom: -10px;

      @include respond-down(M){
        display: none;
      }

      &.xy {
        width: 228px;
        left: -190px;

        @include respond-down(L){
          width: 170px;
          left: -110px;
        }
      }
      &.xx {
        width: 186px;
        right: -200px;

        @include respond-down(L){
          width: 154px;
          right: -120px;
          bottom: -100px;
        }
      }
    }

    .footer-logo {
      display: flex;
      align-items: center;
      font-size: 23px;
      text-transform: uppercase;

      @include respond-down(L){
        font-size: 18px;
      }
      @include respond-down(XS){
        flex-direction: column;
      }

      img.logo {
        margin-right: 32px;
        width: 110px;
        margin-top: -14px;

        @include respond-down(L){
          width: 76px;
          margin-right: 14px;
        }

        @include respond-down(M){
          width: 112px;
          margin-right: 30px;
        }

        @include respond-down(S){
          width: 96px;
          margin-right: 13px;
        }

        @include respond-down(XS){
          width: 104px;
          margin-right: 0;
          margin-bottom: 30px;
        }
      }

      .text {
        color: #232323;
        line-height: 1.3em;

        @include respond-down(M){
          font-size: 28px;
        }
        @include respond-down(S){
          font-size: 22px;
        }
        @include respond-down(XS){
          text-align: center;
          margin-bottom: 22px;
        }
      }
    }

    .social-links {
      display: flex;

      @include respond-down(M){
        position: absolute;
        right: 0;
        bottom: 22px;
      }
      @include respond-down(XS){
        position: initial;
        order: 4;
      }

      .link {
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 16px;
        margin: 0 15px;

        @include respond-down(L){
          font-size: 14px;
        }
        @include respond-down(M){
          margin: 0 12px;
        }

        img {
          @include respond-property('width', 35px, 35px, 30px, 35px, 30px, 35px);
          margin-bottom: 12px;
          transition: transform 0.25s ease;

          @include respond-down(M){
            margin-bottom: 0;
          }
        }

        .text {
          @include respond-down(M){
            display: none;
          }
        }

        @include respond-up(L){
          &:hover {
            img {
              transform: translateY(-6px);
            }
          }
        }
      }
    }

    .email-form {
      width: 270px;
      position: relative;
      z-index: 100;

      @include respond-down(XL){
        width: 230px;
      }
      @include respond-down(M){
        width: 46%;
      }
      @include respond-down(XS){
        width: 94%;
        margin-bottom: 55px;
      }

      .title {
        font-size: 23px;
        margin-bottom: 0.3em;

        @include respond-down(L){
          font-size: 18px;
        }
        @include respond-down(M){
          display: none;
        }
      }

      @include respond-up(L){
        [data-subscribe-me] {
          cursor: pointer;

          svg {
            transition: transform 0.25s ease;
          }

          &:hover {
            svg {
              transform: translateX(10px);
              path {
                fill: #000;
              }
            }
          }
        }
      }

      .desc {
        font-size: 18px;
        margin-bottom: 19px;

        @include respond-down(L){
          font-size: 16px;
          margin-bottom: 14px;
        }
        @include respond-down(M){
          display: none;
        }
      }
    }
  }

  .median {
    flex: 0 0 46%;

    @include respond-down(XL){
      flex: 1 1 50%;
    }

    &:last-child {
      flex: 1 1 54%;

      @include respond-down(XL){
        flex: 1 1 58%;
        margin-right: -8%;
      }
      @include respond-down(M){
        flex: 1 1 50%;
        margin-right: 0;
      }
    }
  }

  .old-only {
    background: #FCE8EB;
    padding: 30px 0;
    position: relative;
    z-index: 50;

    @include respond-down(S){
      padding: 20px 0;
    }

    .mini-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include respond-down(XS){
        flex-direction: column;
        align-items: flex-start;
      }

      br {
        @include respond-down(S){
          display: none;
        }
      }

      .median:first-child {
        @include respond-down(XS){
          margin-bottom: 14px;
        }
      }

      .alert-18 {
        display: flex;
        align-items: center;

        @include respond-down(S){
          margin-bottom: 8px;
        }

        svg {
          @include respond-down(XL){
            width: 68%;
          }
        }

        .circle {
          flex: 0 0 auto;
          width: 56px;
          height: 56px;
          border-radius: 100px;
          border: 2px solid #E93247;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 25px;
          color: #E93247;
          margin-right: 26px;

          @include respond-down(L){
            width: 46px;
            height: 46px;
            font-size: 20px;
            margin-right: 15px;
          }
        }

        .danger {
          font-size: 16px;
          letter-spacing: 0.1em;
          line-height: 1.45em;
          color: #E93247;

          &.rules {
            text-decoration: underline;

            &:hover {
              color: #b22b39;
            }
          }

          @include respond-down(L){
            font-size: 14px;
          }
          @include respond-down(S){
            letter-spacing: 0;
          }
        }
      }

      .info {
        color: #E93247;
        line-height: 1.55em;
        font-size: 14px;
        opacity: 0.55;
        margin-left: 20%;

        @include respond-down(XL) {
          margin-left: 16%;
        }
        @include respond-down(L) {
          margin-left: 10%;
        }
        @include respond-down(M){
          font-size: 12px;
        }
        @include respond-down(S){
          margin-left: 61px;
        }
      }

      .decorator {
        position: absolute;
        right: 0;
        top: 0;
        @include respond-down(M){
          display: none;
        }
      }
    }
  }

  .bottom-line {
    padding: 26px 0;

    .median {
      display: flex;
      align-items: center;

      @include respond-down(S) {
        width: 100%;
      }
    }

    .mini-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include respond-down(S){
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .bottom-end {
      padding-top: 30px;

      @include respond-down(S) {
        border-top: 1px solid #666;
        padding-top: 16px;
        margin-top: 16px;
        flex-direction: initial;
        align-items: flex-start;
        justify-content: space-between;
      }
      @include respond-down(XS){
        flex-direction: column;
        align-items: flex-start;
      }

      .median {
        @include respond-down(S) {
          width: auto;
          flex: 1 1 auto;
        }

        &:first-child {
          @include respond-down(XS){
            display: flex;
            justify-content: space-between;
          }
        }
        &:last-child {
          @include respond-down(S){
            justify-content: flex-end;
          }
        }
      }
    }

    .attention {
      color: #E93247;
      font-size: 14px;
      line-height: 1.45em;

      @include respond-down(L){
        font-size: 12px;
        line-height: 1.45em;
      }
      @include respond-down(S){
        padding-top: 30px;
      }
      @include respond-down(XS){
      }
    }

    .donate-box {
      margin-right: 50px;
      display: flex;
      align-items: center;

      @include respond-down(XL){
        margin-right: 30px;
      }
      @include respond-down(S) {
        flex: 1 1 50%;
        margin-right: 0;
        align-items: flex-end;
      }
      @include respond-down(XS){
        flex: 1 1 38%;
      }

      img {
        display: block;
        margin-right: 10px;

        @include respond-down(XS){
          width: 46px;
        }
      }

      a {
        font-size: 16px;
        text-transform: uppercase;
        margin-top: 10px;
        line-height: 1.35em;

        @include respond(L) {
          font-size: 14px;
        }
        @include respond(XS) {
          font-size: 14px;
        }
      }
    }

    .contact-email {
      margin-top: 12px;

      @include respond-down(M) {
        margin-right: 20px;
      }

      @include respond-down(S) {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        flex: 1 1 50%;
      }
      @include respond-down(XS) {
        margin-right: 0;
        flex: 1 1 20%;
      }

      .text {
        font-size: 14px;
        margin-bottom: 0.5em;

        @include respond-down(L) {
          font-size: 12px;
        }
        @include respond-down(M) {
          font-size: 14px;
        }

        @include respond-down(S) {
          font-size: 12px;
          white-space: nowrap;
          color: #232323;
          margin: 0 20px 4px 0;
        }
        @include respond-down(XS) {
          margin-right: 0;
        }
      }

      .email {
        font-size: 20px;
        letter-spacing: 0.02em;
        color: #E93247;
        text-decoration: underline;

        @include respond-down(L) {
          font-size: 18px;
        }
        @include respond-down(S){
          font-size: 14px;
        }

        @include respond-up(L) {
          &:hover {
            color: #b22b39;
          }
        }
      }
    }

    .system-pages {
      display: flex;
      align-items: center;

      @include respond-down(M) {
        flex: 0 0 100%;
      }
      @include respond-down(S) {
        display: inline-flex;
        justify-content: flex-end;
        text-align: right;
      }
      @include respond-down(XS) {
        flex: 1 1 100%;
        flex-direction: column;
        margin: auto;
        align-items: flex-start;
      }

      .list {
        display: flex;
        align-items: center;
        opacity: 0.7;
        justify-content: flex-end;
        flex-wrap: wrap;

        @include respond-down(S) {
          justify-content: flex-start;
        }
      }

      .link {
        color: #8D8888;
        font-size: 12px;

        @include respond-down(L) {
          font-size: 11px;
        }
        @include respond-down(M){
          font-size: 10px;
          padding-top: 0.2em;
        }
        @include respond(S) {
          margin: 0 0 0 10px;
          padding-bottom: 0.2em;
        }
        @include respond-down(XS) {
          margin: 1px 0;
        }

        @include respond-up(L){
          &:hover {
            color: #E81E35;
          }
        }
      }

      .separator {
        height: 16px;
        width: 1px;
        background: #8D8888;
        margin: 0 12px;
        display: block;

        @include respond-down(XL) {
          margin: 0 14px;
        }
        @include respond-down(L) {
          height: 12px;
          margin: 0 5px;
        }
        @include respond-down(M){
          display: none;
        }
      }
    }

    .copyright {
      color: #8D8888;
      font-size: 14px;
      display: inline-block;
      margin-right: 20px;

      @include respond-down(L) {
        font-size: 12px;
      }
      @include respond-down(XS) {
        margin-right: 0;
        margin-bottom: 6px;
      }
    }
  }
}
