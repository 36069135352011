.header-box {
  padding-top: 0;
  margin-bottom: 46px;

  .menu-line {
    padding: 12px 0;
    background: transparent;
    transition: background 0.25s ease;
    min-height: 64px;

    .container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;

      .corner-links {
        display: none;
      }

      .search-wine {
        display: none;
      }

      .logo {
        z-index: 50;
        position: relative;

        svg {
          width: 162px;

          @include respond-down(S) {
            width: 136px;
          }
        }

        .logo-img {
          display: none;
        }
        .logo-text {
          margin-top: -6px;
          margin-left: 3px;
        }
      }

      .find-wine {
        position: absolute;
        width: 65px;
        height: 50px;
        right: 30px;
        top: -5px;
        transition: width 0.35s ease, right 0.1s ease;

        @include respond-down(S){
          right: 24px;
        }

        .box {
          transition: background 0.35s ease;
          background: transparent;
          border-radius: 25px;
          padding: 12px 18px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;

          .default {
            display: flex;
            align-items: center;
            cursor: pointer;

            input {
              background: transparent;
              height: 45px;
              margin: -10px 0;
              width: 0;
              border: none;
              padding: 0;
              color: #FFF;
              font-size: 16px;
              font-family: 'Mitias', sans-serif;
              cursor: pointer;

              &::placeholder {
                transition: color 0.25s ease;
                text-transform: uppercase;
                color: transparent;
              }
            }

            svg {
              width: 24px;
              flex: 0 0 24px;

              @include respond-down(S){
                width: 22px;
                margin: 2px;
              }

              path {
                transition: fill 0.25s ease;
              }
            }

            &:hover {
              input::placeholder {
                color: #E81E35;
              }

              svg path {
                fill: #E81E35;
              }
            }
          }

          .close {
            position: absolute;
            z-index: 20;
            width: 50px;
            height: 50px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            top: 0;
            right: 0;
            display: none;

            svg {
              width: 12px;
              height: 12px;

              path {
                transition: fill 0.15s ease;
              };
            }

            &:hover {
              svg path { fill: #232323 }
            }
          }

          .results {
            display: none;

            .space {
              height: 1px;
              margin: 8px 0 2px 0;
              background: #FFF;
            }

            .result {
              height: 54px;
              color: #FFF;
              display: flex;
              align-items: center;
              padding-left: 4px;
              padding-top: 14px;
              transition: color 0.25s ease;

              @include respond-down(S){
                height: 44px;
              }

              svg {
                width: 16px;
                margin-right: 10px;
                margin-top: -7px;

                @include respond-down(S){
                  width: 14px;
                }
              }

              &.result-fail {
                height: 40px;
                position: relative;
                margin-bottom: 24px;
                padding-left: 0;

                svg {
                  width: 28px;
                  margin-top: 0;

                  @include respond-down(S){
                    width: 24px;

                  }
                }

                .go-all {
                  position: absolute;
                  right: 0;
                  bottom: -14px;
                  color: #FFF;
                  text-decoration: underline;
                  cursor: pointer;

                  &:hover {
                    color: #232323;
                  }
                }
              }

              &:not(.result-fail):hover {
                color: #232323;
              }

              &:not(.result-fail):last-child {
                margin-bottom: 10px;
              }
            }
          }
        }

        &.activated {
          width: 410px;

          @include respond-down(XL){
            width: 340px;
          }
          @include respond-down(XL){
            width: 100%;
            //right: 0;
            z-index: 60;
          }
          @include respond-down(M){
            right: 0;
          }

          .close {
            display: flex;
          }

          .results {
            display: block;
          }

          .box {
            background: #E93247;

            .default {
              svg path {
                fill: #FFF;
              }
              input {
                cursor: initial;

                @include respond-down(M){
                  width: 100%;
                  padding: 10px;
                }

                &::placeholder {
                  color: #FFF;
                }
              }
            }
          }
        }
      }

      .menu-opener-box {
        .menu-opener {
          width: 28+5+5px;
          height: 26+5+5px;
          padding: 4px 5px 5px 5px;

          @include respond-down(S){
            height: 22+5+5px;
            width: 32px;
          }

          & > div {
            display: flex;
            justify-content: space-between;

            .l1 {
              width: 18px;

              @include respond-down(S){
                width: 14px;
              }
            }

            .l2 {
              width: 6px;

              @include respond-down(S){
                width: 4px;
              }
            }
          }

          .line {
            width: auto;
            height: 4px;
            margin: 3px 0 2px 0;
            background: #232323;
            border-radius: 5px;

            @include respond-down(S){
              height: 3px;
            }
          }
        }
      }
    }
  }
}

body.dark-theme {
  svg.logo-text, svg.logo-img {
    path {
      fill: #FFF;
    }
    path.klaksa {
      fill: #BE051B;
    }
  }
  .menu-opener {
    .line {
      background: #FFF !important;
    }
  }

  .header-box .menu-line {
    @include respond-down(M) {
      background: #E93247;
    }

    .find-wine {
      &.activated {
        .box {
          box-shadow: inset 0 0 0 2px #FFF;
        }
      }
    }
  }

  .find-wine {
    .default {
      input {
        &::placeholder {
          color: #FFF !important;
        }
      }
      svg path {
        fill: #FFF;
      }
    }
  }
}

@include respond-down(M) {
  [data-scrolled="true"] {
    .header-box {
      height: 64px;
    }
    .menu-line-wrapper {
      position: fixed;
      left: 0;
      z-index: 300;
      top: 0;
      right: 0;
      transition: transform 0.25s ease, opacity 0.35s ease;
      transform: translateY(-64px);
      opacity: 0;
    }

    &:not(.dark-theme) {
      .menu-line {
        background: #FCE8EB;

        .container {
          .find-wine {
            .box {
              .space {
                background: #232323 !important;
              }
              .result, .go-all {
                color: #232323 !important;
              }
            }

            &.activated {
              .box {
                background: #FCE8EB;
                box-shadow: inset 0 0 0 2px #232323;

                input, input::placeholder {
                  color: #232323 !important;
                }

                svg path {
                  fill: #232323 !important;
                }
              }
            }
          }
        }
      }
    }
  }

  [data-scroll-up="true"] {
    .menu-line-wrapper {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

/* ------------------------------------------------------------------------ */
// Desktop

@include respond-up(L) {
  .header-box {
    font-size: 16px;

    .menu-line {
      .container {
        height: 64px;

        .corner-links {
          display: flex;
          align-items: center;

          .link {
            text-transform: uppercase;
            color: #232323;

            @include respond-up(L){
              &:hover {
                color: #E81E35;
              }
            }
          }

        }

        .search-wine {
          text-transform: uppercase;
          cursor: pointer;
          color: #FFF;
          display: flex;
          align-items: center;
          justify-content: space-around;
          position: relative;
          width: 150px;
          height: 100px;
          margin: -10px  0 -10px -20px ;

          svg {
            position: absolute;
            left: 0;
            top: -16px;
            z-index: 10;
            width: 150px;
            height: 130px;

            path {
              transition: fill 0.25s ease;
            }
          }

          .text {
            position: relative;
            z-index: 20;
          }

          @include respond-up(L){
            &:hover {
              color: #FFF;

              svg path {
                fill: #B62E3D;
              }
            }
          }
        }

        .logo {
          width: 96px;
          position: absolute;
          margin-left: -48px;
          left: 50%;
          top: -14px;

          svg.logo-text {
            display: none;
          }
          svg.logo-img {
            display: block;
            width: 100%;
          }
        }

        .find-wine {
          right: 160px;
          width: 160px;
          top: 7px;

          &.activated {
            width: 280px;
          }

          .box {
            .default {
              svg {
                flex: 0 0 18px;
              }
              input {
                width: 100%;
                margin: -10px 8px;

                &::placeholder {
                  color: #232323;
                }
              }
            }

            .results {
              .go-all {
                bottom: -23px !important;
              }
            }
          }
        }

        .menu-opener-box {
          display: none;
        }
      }
    }
  }

  body.dark-theme {
    .search-wine {
      svg path {
        fill: #BE051B;
      }
    }
    .find-wine:not(.activated) {
      .box:hover {
        input {
          &::placeholder {
            color: #232323 !important;
          }
        }
        svg path {
          fill: #232323 !important;
        }
      }
    }
  }
}

@include respond-up(XL) {
  .header-box {
    font-size: 20px;

    .menu-line {
      padding: 32px 0;

      .container {
        .search-wine {
          display: flex;
          margin: -30px -24px -30px -40px;

          svg {
            width: 190px;
            height: 120px;
            left: -20px;
            top: -10px;
          }
        }

        .logo {
          width: 122px;
          margin-left: -61px;
        }

        .find-wine {
          width: 180px;

          input {
            font-size: 20px !important;
          }

          &.activated {
            width: 410px;
          }
        }
      }
    }
  }
}

@include respond-up(XXL) {
}
